import React, { useRef } from "react";
import { PageParams } from "../../../types";
import SEO from "../../components/seo/seo";
import Hero from "../../components/hero/hero";
import Section from "../../components/air-section/air-section";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import SvgImage from "../../components/svg-image/svg-image";
import Footer from "../../components/footer/footer";
import StickyContainer from "../../components/sticky/sticky-container";
import Customers from "../../components/customers/customers";
import CallFooter from "../../components/call-footer/call-footer";
import HeaderBig from "../../components/header-big/header-big";
import ScrollButton from "../../components/scroll-button/scroll-button";
import MapApi from "../../components/map-api/map-api";
import Button from "../../components/button/button";
//SVG icon
import ServiceIconWebsite from "../../images/offer/service_icon_website.svg";
import ServiceIconECommerce from "../../images/offer/service_icon_e-commerce.svg";
import ServiceIconMobile from "../../images/offer/service_icon_mobile.svg";
import ServiceIconDesign from "../../images/offer/service_icon_design.svg";
import ServiceIconDesktop from "../../images/offer/service_icon_desktop.svg";
import ServiceIconSoftware from "../../images/offer/service_icon_software.svg";
import ServiceIconConsulting from "../../images/offer/service_icon_consulting.svg";
import ServiceIconInfrastructure from "../../images/offer/service_icon_infrastructure.svg";
//SVG
import GooglePlaySVG from "../../images/svg/google_play.inline.svg";
import AppStoreSVG from "../../images/svg/app_store.inline.svg";
import OpenCart from "../../images/technologies/logo_opencart.inline.svg";
import Magento from "../../images/technologies/logo_magento.inline.svg";
import Woocommerce from "../../images/technologies/logo_woocommerce.inline.svg";
import PrestaShop from "../../images/technologies/logo_prestashop.inline.svg";
import LogoAngular from "../../images/technologies/logo_angular.inline.svg";
import ReactLogo from "../../images/technologies/logo-react.inline.svg";
import VueJs from "../../images/technologies/logo-vuejs.inline.svg";
import NodeJs from "../../images/technologies/logo_nodejs.inline.svg";
import Laravel from "../../images/technologies/logo-laravel.inline.svg";
import Php from "../../images/technologies/logo_php.inline.svg";
import Flutter from "../../images/technologies/logo-flutter.inline.svg";
import NativeScript from "../../images/technologies/logo_nativescript.inline.svg";
import LogoElectron from "../../images/technologies/logo_electron.inline.svg";
import Ionic from "../../images/technologies/logo_ionic.inline.svg";

const shortcodes = {
  Hero,
  Section,
  CallFooter,
  Footer,
  HeaderBig,
  Img,
  ScrollButton,
  GooglePlaySVG,
  AppStoreSVG,
  SvgImage,
  BackgroundImage,
};
const RangeItemPage = (params: PageParams<PageContext>) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const node = params.pageContext.node;
  // console.log("lang", language);
  // console.log("node", node);
  const frontmatter = { ...node.childMdx.frontmatter };

  // const scrollRef = useRef();
  // useEffect(() => {
  //   return () => {
  //     GlobalState.set({ headerColor: null });
  //     // Anything in here is fired on component unmount.
  //   };
  // }, []);

  const data = useStaticQuery(
    graphql`
      {
        strony_internetowe: file(
          relativePath: { eq: "strony-internetowe/yummypix-rwd.png" }
        ) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sklepy_internetowe: file(
          relativePath: { eq: "sklepy-internetowe/wallers-design.png" }
        ) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portale_internetowe: file(
          relativePath: { eq: "portale-internetowe/curem_rwd_cms.png" }
        ) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aplikacja_webowa: file(
          relativePath: { eq: "aplikacje/aplication_bp.png" }
        ) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aplikacja_mobilna: file(
          relativePath: {
            eq: "aplikacje-mobilne/aplikacja_mobilna_zielone_pogotowie.png"
          }
        ) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        grafika: file(
          relativePath: { eq: "projektowanie-graficzne/grafika_3d.png" }
        ) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <>
      <SEO
        title={frontmatter.title}
        description={
          frontmatter.short_description + " | Aplinet ☎ +48 665 035 451"
        }
        lang={language}
      />
      <Hero>
        <div className="container-xs header-padding">
          <h1>{frontmatter.city} - strony www i&nbsp;aplikacje</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Projektujemy i&nbsp;tworzymy nowoczesne strony internetowe,
              aplikacje mobilne i&nbsp;desktopowe dla klientów z&nbsp;
              {frontmatter.city_from}
            </h2>
          </div>
          <h4>
            Od 2013 r. programujemy i&nbsp;wdrażamy skalowalne systemy
            informatyczne i&nbsp;produkty cyfrowe, www, sklepy internetowe
            e-commerce, CMS, SEO, aplikacje Google Play i&nbsp;App Store,
            grafikę komputerową.
          </h4>
        </div>
        <ScrollButton
          style={{
            position: "absolute",
            left: "50%",
            bottom: "5%",
            transform: "translateX(-50%)",
          }}
          to={scrollRef}
        />
      </Hero>
      <Section ref={scrollRef}>
        <div className="container">
          <HeaderBig
            tag="h2"
            span={"Jesteś z okolic " + frontmatter.city_from}
            headerText="Cieszymy się, że do nas trafiłeś"
          />
        </div>
        <div className="r :c-12 :c-6:sm+ :a-ce :w">
          <div className="container">
            <p>
              Jeżeli <strong>szukasz</strong> profesjonalnej, doświadczonej{" "}
              <strong>
                Agencji Interaktywnej lub Software House'u z&nbsp;
                {frontmatter.city_from}
              </strong>
              , to jesteś w&nbsp;dobrym miejscu.
            </p>
            <p>
              Nasza firma stworzy dla Ciebie:{" "}
              <strong>stronę internetową</strong>, <strong>portal www</strong>,{" "}
              <strong>sklep internetowy e-commerce</strong>,{" "}
              <strong>aplikację webową</strong> lub{" "}
              <strong>aplikację mobilną</strong>. Zadba o&nbsp;profesjonalny
              interfejs, zgodny ze wszystkimi zasadami <strong>UI</strong>{" "}
              i&nbsp;<strong>UX</strong>. Zaprojektuje niepowtarzalną grafikę{" "}
              <strong>2D</strong> lub <strong>3D</strong>.
            </p>
            <p>
              Od lat realizujemy nasze usługi w&nbsp;{frontmatter.region_where}{" "}
              i&nbsp;zapewnimy bezproblemową obsługę Twojego projektu IT.
            </p>
            <div className="">
              <Button color="primary" type="default" to="/o-nas" language="pl">
                Dowiedz się o&nbsp;nas więcej
              </Button>
            </div>
          </div>
          <div>
            <CallFooter />
          </div>
        </div>
        <div className="container r :s :p :c-3 :c-1:sm+ :w :!w:sm+">
          <div className="align-center">
            <img
              src={ServiceIconWebsite}
              title="Strony internetowe"
              alt="Strony internetowe"
              className="image"
            />
          </div>
          <div className="align-center">
            <img
              src={ServiceIconECommerce}
              title="Sklepy internetowe"
              alt="Sklepy internetowe"
              className="image"
            />
          </div>
          <div className="align-center">
            <img
              src={ServiceIconDesktop}
              title="Portale internetowe"
              alt="Portale internetowe"
              className="image"
            />
          </div>
          <div className="align-center">
            <img
              src={ServiceIconSoftware}
              title="Aplikacje webowe"
              alt="Aplikacje webowe"
              className="image"
            />
          </div>
          <div className="align-center">
            <img
              src={ServiceIconMobile}
              title="Aplikacje mobilne"
              alt="Aplikacje mobilne"
              className="image"
            />
          </div>
          <div className="align-center">
            <img
              src={ServiceIconDesign}
              title="Grafika komputerowa"
              alt="Grafika komputerowa"
              className="image"
            />
          </div>
          <div className="align-center">
            <img
              src={ServiceIconConsulting}
              title="Strategia i doradztwo w projektach IT"
              alt="Strategia i doradztwo w projektach IT"
              className="image"
            />
          </div>
          <div className="align-center">
            <img
              src={ServiceIconInfrastructure}
              title="Administracja"
              alt="Administracja"
              className="image"
            />
          </div>
        </div>
      </Section>
      <Section className="container">
        <HeaderBig
          tag="h2"
          span="Czym dokładnie się zajmujemy?"
          headerText="Świetnie odnajdziemy się w każdym z&nbsp;poniższych wyzwań"
        />
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky>
            <Img
              fluid={data.strony_internetowe.childImageSharp.fluid}
              className="image-test"
              alt={
                "Grafika przedstawia mockup strony internetowj " +
                frontmatter.cyty
              }
              title={"Strony internetowe " + frontmatter.cyty}
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="WWW"
              headerText={"Strony internetowe " + frontmatter.city}
            />
            <p>
              Przygotujemy responsywną <strong>stronę internetową RWD</strong>,
              prostą <strong>wizytówkę www</strong> opartą o&nbsp;system
              zarządzania treścią <strong>CMS</strong> (dedykowany lub typu Open
              Source). Dodatkowo zadbamy o&nbsp;dobre pozycjonowanie{" "}
              <strong>SEO</strong> uwzględniając geolokalizację w&nbsp;
              {frontmatter.city_where} i&nbsp;okolicach.
            </p>
            <p>
              Projekty stron internetowych przygotujemy na podstawie{" "}
              <strong>indywidualnych projektów graficznych</strong> lub na
              szablonach www. Optymalizujemy koszty realizacji aby wycena strony
              internetowej w&nbsp;{frontmatter.city_where} była atrakcyjna.
            </p>
            <h4>
              Zapewniamy wysoką jakość stron internetowych w&nbsp;
              {frontmatter.city_where}
            </h4>
            <ul>
              <li>pozycjonowanie SEO {frontmatter.city}</li>
              <li>strona internetowa {frontmatter.city}</li>
              <li>strona wizytówka dla firmy z&nbsp;{frontmatter.city_from}</li>
            </ul>
            <div className="">
              <Button
                color="primary"
                type="default"
                to="/strony-internetowe"
                language="pl"
              >
                Dowiedz się o&nbsp;stronach www
              </Button>
            </div>
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="E-Commerce"
              headerText={"Sklepy internetowe " + frontmatter.city}
            />
            <p>
              Stworzymy <strong>sklep internetowy</strong> lub rozbudowaną,
              dedykowaną{" "}
              <strong>
                platformę sprzedażową e-commerce w {frontmatter.city_where}
              </strong>
              . Zapewnimy atrakcyjną szatę graficzną oraz intuicyjny interfejs,
              który przyciągnie do sklepu nowych użytkowników.
            </p>
            <p>
              Wybierzemy odpowiedni{" "}
              <strong>
                system do stworzenia sklepu internetowego e-&nbsp;commerce
              </strong>
              <br />
              <em>Sklep internetowy Prestashop</em>,{" "}
              <em>Sklep internetowy WooCommerce</em>,{" "}
              <em>Sklep internetowy Magento</em>,{" "}
              <em>Sklep internetowy OpenCart</em>, lub{" "}
              <em>dedykowane rozwiązanie</em> pozwolą na przygotowanie
              w&nbsp;pełni funkcjonalnego oprogramowania w&nbsp;optymalnym
              budżecie.
            </p>
            <h4>W powiecie {frontmatter.district_where} realizujemy:</h4>
            <ul>
              <li>
                sklepy internetowe w&nbsp;{frontmatter.city_where}{" "}
                i&nbsp;okolicy
              </li>
              <li>tworzenie platform e-commerce {frontmatter.city}</li>
              <li>
                projektowanie i&nbsp;programowanie sklepów {frontmatter.city}
              </li>
              <li>
                mobilne sklepy internetowe RWD w&nbsp;
                {frontmatter.region_where}
              </li>
            </ul>
            <div className="">
              <Button
                color="primary"
                type="default"
                to="/sklepy-internetowe"
                language="pl"
              >
                Dowiedz się o&nbsp;e-commerce
              </Button>
            </div>
          </div>
          <div data-sticky className="container">
            <Img
              fluid={data.sklepy_internetowe.childImageSharp.fluid}
              className="image-test"
              alt={
                "Grafika przedstawia mockup sklepu internetowego " +
                frontmatter.cyty
              }
              title={"E-commerce, sklep internetowy " + frontmatter.cyty}
            />
          </div>
        </StickyContainer>
        <div className="container r :c-6 :c-3:xs+ :a-ce :w">
          <div className="item" style={{ padding: "1em" }}>
            <Woocommerce />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <Magento />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <PrestaShop />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <OpenCart />
          </div>
        </div>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky>
            <Img
              fluid={data.portale_internetowe.childImageSharp.fluid}
              className="image-test"
              alt={
                "Grafika przedstawia mockup portalu internetowego " +
                frontmatter.cyty
              }
              title={"Portal internetowy www " + frontmatter.cyty}
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="WWW"
              headerText={"Portale internetowe " + frontmatter.city}
            />
            <p>
              Przygotujemy <strong>rozbudowany portal internetowy</strong> oraz{" "}
              <strong>zaawansowany serwis www {frontmatter.city}</strong>{" "}
              i&nbsp;okolice.
            </p>
            <p>
              Tworzenie portali internetowych, które są innowacyjne
              i&nbsp;spełniaja oczekiwania użytkowników nie sprawia nam
              problemu. Wspólnie z klientami, realizujemy{" "}
              <strong>
                warsztaty User stories w&nbsp;{frontmatter.city_where}
              </strong>
              .
            </p>
            <h4>
              Projektując portal internetowy w&nbsp;{frontmatter.city_where}{" "}
              zapewniamy:
            </h4>
            <ul>
              <li>
                łatwą intuicyjną obsługę, prawidłowe UI/UX, realizację na{" "}
                terenie powiatu {frontmatter.district_from}
              </li>
              <li>
                optymalizacje pod urządzenia mobilne i&nbsp;dostępność nie tylko
                z&nbsp;{frontmatter.city_from}
              </li>
            </ul>
            <div className="">
              <Button
                color="primary"
                type="default"
                to="/portale-internetowe"
                language="pl"
              >
                Dowiedz się o&nbsp;portalach www
              </Button>
            </div>
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="APP"
              headerText={"Aplikacje webowe " + frontmatter.city}
            />
            <p>
              Specjalizujemy się w&nbsp;tworzeniu zaawansowanych{" "}
              <strong>aplikacji webowych i&nbsp;systemów</strong> działających
              za pośrednictwem przeglądarki internetowej na terenie{" "}
              {frontmatter.city_from}, powiatu {frontmatter.district_from}{" "}
              i&nbsp;w&nbsp;całej {frontmatter.region_where}.
            </p>
            <p>
              <strong>
                Do tworzenia aplikacji webowych wykorzystujemy najnowsze
                technologie
              </strong>
              : <em>Angular</em>, <em>Node</em>, <em>React</em>, <em>Vue</em>,{" "}
              <em>docker</em>. Jesteśmy specjalistami w&nbsp;ich wykorzystaniu.
            </p>
            <h4>W {frontmatter.city_where}, zrealizujemy:</h4>
            <ul>
              <li>aplikację konkursową</li>
              <li>
                system do zarządzania firmą w&nbsp;{frontmatter.city_where}
              </li>
              <li>
                aplikację ułatwiającą zarządzanie pracownikami, zamówieniami,
                rezerwacjami w&nbsp;{frontmatter.district_where}
              </li>
              <li>
                aplikację z&nbsp;bazą danych, konfigurator produktów, boty.
              </li>
            </ul>
            <div className="">
              <Button
                color="primary"
                type="default"
                to="/aplikacje"
                language="pl"
              >
                Dowiedz się o&nbsp;Aplikacjach
              </Button>
            </div>
          </div>
          <div data-sticky>
            <Img
              fluid={data.aplikacja_webowa.childImageSharp.fluid}
              className="image-test"
              alt={
                "Grafika przedstawia mockup aplikacji webowej " +
                frontmatter.cyty
              }
              title={"Aplikacja webowa " + frontmatter.cyty}
            />
          </div>
        </StickyContainer>
        <div className="container r :c-4 :c-2:xs+ :a-ce :w">
          <div className="item" style={{ padding: "1em" }}>
            <LogoAngular />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <ReactLogo />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <VueJs />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <NodeJs />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <Laravel />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <Php />
          </div>
        </div>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="container">
            <Img
              fluid={data.aplikacja_mobilna.childImageSharp.fluid}
              className="image-test"
              alt={
                "Grafika przedstawia mockup aplikacji mobilnej " +
                frontmatter.cyty
              }
              title={"Aplikacja mobilna " + frontmatter.cyty}
            />
            <div className="r :c-6 :a-ce :p">
              <a
                href="https://play.google.com/store/apps/developer?id=Aplinet+Apps"
                alt="Aplikacja Zielone Pogotowie w sklepie Google Play"
                rel="noreferrer noopener"
                target="_blank"
                style={{ margin: "1em 0" }}
              >
                <GooglePlaySVG width="100%"></GooglePlaySVG>
              </a>
              <a
                href="https://apps.apple.com/us/developer/konrad-frysiak/id1508284249"
                alt="Aplikacja Zielone Pogotowie w sklepie App Store"
                rel="noreferrer noopener"
                target="_blank"
                style={{ margin: "1em 0" }}
              >
                <AppStoreSVG width="100%"></AppStoreSVG>
              </a>
            </div>
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="APP"
              headerText={"Aplikacje mobilne " + frontmatter.city}
            />
            <p>
              <strong>
                Projektujemy aplikacje mobilne Android i&nbsp;iOS w&nbsp;
                {frontmatter.city_where}
              </strong>{" "}
              i&nbsp;{frontmatter.region_where}. Znamy się na większości
              mobilnych technologii i&nbsp;potrafimy wybrać odpowiednią do
              potrzeb projektu. Nasze doświadczenie zostało docenione nie tylko
              przez użytkowników (Doskonałe opinie w&nbsp;sklepach Google
              i&nbsp;App Store), ale również przez Gazetę Wyborczą, Twój Styl,
              Komputronik.
            </p>
            <p>
              Nasi programiści specjalizują się w&nbsp;technologiach:{" "}
              <em>Flutter</em>, <em>NativeScript</em>, <em>Electron</em>,{" "}
              <em>ionic</em> oraz <em>rozwiązaniach natywnych</em>.
            </p>
            <h4>
              Jesteśmy doświadczenie w&nbsp;projektowaniu i&nbsp;wdrażaniu
              aplikacji mobilnych w&nbsp;{frontmatter.city_where}
            </h4>
            <ul>
              <li>
                Tworzymy aplikacje na systemy Android na terenie{" "}
                {frontmatter.city_from}.
              </li>
              <li>
                Tworzymy aplikacje na systemy iOS na terenie{" "}
                {frontmatter.city_from}.
              </li>
              <li>Piszemy testy automatyczne dla aplikacji mobilnych.</li>
            </ul>
            <div className="">
              <Button
                color="primary"
                type="default"
                to="/aplikacje-mobilne"
                language="pl"
              >
                Dowiedz się więcej o&nbsp;mobile app
              </Button>
            </div>
          </div>
        </StickyContainer>
        <div className="container r :c-4 :c-2:xs+ :a-ce :w">
          <div className="item" style={{ padding: "1em" }}>
            <Flutter />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <NativeScript />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <LogoElectron />
          </div>
          <div className="item" style={{ padding: "1em" }}>
            <Ionic />
          </div>
        </div>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="Design"
              headerText={"Grafika komputerowa, 2D i 3D " + frontmatter.city}
            />
            <p>
              Najlepsze{" "}
              <strong>
                studio graficzne i&nbsp;reklamowe w&nbsp;
                {frontmatter.city_where}
              </strong>
              . Specjalizujemy się w&nbsp;projektach{" "}
              <strong>stron internetowych</strong> oraz{" "}
              <strong>layoutach i&nbsp;interfejsach aplikacji</strong> webowych,
              mobilnych i&nbsp;desktopowych.
              <br />
              Przygotujemy też każdy inny rodzaj grafiki:{" "}
              <strong>identyfikację wizualną</strong>,{" "}
              <strong>wizytówki</strong>, <strong>plakaty</strong>,{" "}
              <strong>bilbordy</strong>, <strong>foldery reklamowe</strong>,{" "}
              <strong>projekty opakowań</strong>.
            </p>
            <p>
              Nasz{" "}
              <strong>grafik kmputerowy z&nbsp;{frontmatter.city_from}</strong>{" "}
              to gwarancja przygotowania niepowtarzalnej grafiki komputerowej 2D
              i&nbsp;3D.
            </p>
            <h4>
              W&nbsp;powiecie {frontmatter.district_where}, zakres usług
              obejmuje:
            </h4>
            <ul>
              <li>
                tworzenie projektów stron internetowych dla{" "}
                {frontmatter.city_from}
              </li>
              <li>
                identyfikację wisualną firm z&nbsp;powiatu{" "}
                {frontmatter.district_from}
              </li>
              <li>
                wizytówki, foldery, banery w&nbsp;{frontmatter.city_where}
              </li>
            </ul>
            <div className="">
              <Button
                color="primary"
                type="default"
                to="/projektowanie-graficzne"
                language="pl"
              >
                Dowiedz się więcej o&nbsp;grafice
              </Button>
            </div>
          </div>
          <div data-sticky>
            <Img
              fluid={data.grafika.childImageSharp.fluid}
              className="image-test"
              alt={
                "Grafika komputerowa, interfejsy aplikacji, UI i UX " +
                frontmatter.cyty
              }
              title={
                "Grafika, projekty interfejsów UI i UX " + frontmatter.cyty
              }
            />
          </div>
        </StickyContainer>
      </Section>
      <Section className="container-xs">
        <HeaderBig
          tag="h2"
          span="Inne usługi"
          headerText={"Strategia i doradztwo techniczne IT " + frontmatter.city}
        />
        <p>
          Jesteśmy ambitni!{" "}
          <strong>Nasi progtamiści z&nbsp;{frontmatter.city_from}</strong>{" "}
          nieustannie doskonalą swoje umiejętności i&nbsp;uwielbiają wyzwania.
        </p>
        <p>
          Jeżeli szukasz innej usługi niż tworzenie stron internetowych,
          programowanie aplikacji webowych, przygotowanie aplikacji mobilnej na
          telefony i&nbsp;tablety.{" "}
          <strong>Odezwij się do nas, postaramy się pomóc!</strong>
        </p>
      </Section>
      <div className="container">
        <HeaderBig
          tag="h2"
          span="Współpraca"
          headerText={
            "Zostań jendym z zadowolonych klientów z " + frontmatter.city_from
          }
        />
      </div>
      <CallFooter />
    </>
  );
};

export default RangeItemPage;
